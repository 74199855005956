.header{

	.lang{
		float: right;
		width: 27.5%;
		padding: 28px 0 28px 23px;
	}
}

.t-panel{
  float: left;
  width: 72.5%;
  position: relative;
  z-index: 1;
  color: #D3D3D3;
  padding: 20px 38px 21px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
	font-size: 14px;


  &:before{
  	content: " ";
  	position: absolute;
  	top: 0;
  	bottom: 0;
  	right: 0;
  	margin-right: -1px;
  	width: 5000px;
  	background: $bg;
  	z-index: -1;
  	box-shadow: 0 5px 20px rgba(0,0,0,0.1);
  	border-radius: 0 0 20px 0;
  }
}

.balance{
	padding: 14px 30px 16px 24px;
	position: relative;
	border-radius: 5px;
	background-image: repeating-linear-gradient(0deg, rgba(244, 195, 92, 0.4), rgba(244, 195, 92, 0.4) 8px, transparent 8px, transparent 13px, rgba(244, 195, 92, 0.4) 13px), repeating-linear-gradient(90deg, rgba(244, 195, 92, 0.4), rgba(244, 195, 92, 0.4) 8px, transparent 8px, transparent 13px, rgba(244, 195, 92, 0.4) 13px), repeating-linear-gradient(180deg, rgba(244, 195, 92, 0.4), rgba(244, 195, 92, 0.4) 8px, transparent 8px, transparent 13px, rgba(244, 195, 92, 0.4) 13px), repeating-linear-gradient(270deg, rgba(244, 195, 92, 0.4), rgba(244, 195, 92, 0.4) 8px, transparent 8px, transparent 13px, rgba(244, 195, 92, 0.4) 13px);
	background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
	background-position: 0 0, 0 0, 100% 0, 0 100%;
	background-repeat: no-repeat;
	margin-right: 25px;
	flex: 0 0 278px;
	font-weight: 500;


	&:before{
		content: " ";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
		width: 39px;
		height: 41px;
		background: url("../img/icons/badge.svg") no-repeat 50% 50%;
		background-size: contain;
	}

  .trx{
    background-image: url("../img/words/trx-14-500-D3D3D3.png");
    width: 26px;
    height: 10px; 
  }

  .bnb{
    background-image: url("../img/words/bnb-14-500-D3D3D3.png");
    width: 27px;
    height: 10px; 
  }

	&-val{
		color: $color2;
		font-size: 18px;
		font-weight: 600;

	  .trx{
	    background-image: url("../img/words/trx-18-600-DF9906.png");
	    width: 35px;
	    height: 15px;
	    margin-bottom: -1px;
	  }

	  .bnb{
	    background-image: url("../img/words/bnb-18-500-DF9906.png");
	    width: 36px;
	    height: 14px;
	    margin-bottom: -1px;
	  }
	}
}

.profit{
	font-size: 16px;
	color: #D3D3D3;
	margin-right: 25px;
	line-height: normal;
	font-weight: 500;

	.next{
		font-size: 13px;
    margin-top: 9px;
    font-weight: normal;

	  .trx{
	    background-image: url("../img/words/trx-13-400-DF9906.png");
	    width: 24px;
	    height: 10px;
	  }

	  .bnb{
	    background-image: url("../img/words/bnb-13-400-D3D3D3.png");
	    width: 25px;
	    height: 10px;
	  }
	}
}

.hstats{
  flex-grow: 1;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.lang{
	font-size: 14px;
	color: #86641D;

	ul{
		list-style-type: none;
		margin: 15px 0 0 -2px;
		padding: 0;
		cursor: default;
		display: flex;
		width: 100%;
		justify-content: space-between;

		li{
			display: inline-block;
			vertical-align: top;
			margin: 0 0 0 2px;

			&:before{
				content: none;
			}
		}

		&:hover{
			.lang-icon{
				transform: scale(0.9);
				opacity: 0.8;

				&:hover{
					transform: scale(1.1);
					opacity: 1;
				}
			}
		}
	}

	a{
		border: none;
		display: inline-block;
		vertical-align: top;
	}

	&-icon{
		width: 23px;
		height: 23px;
		overflow: hidden;
		border-radius: 50%;
		display: inline-block;
		vertical-align: top;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		transition: transform 0.24s, opacity 0.24s;
		box-shadow: 1px 2px 4px rgba(0,0,0,0.2);

		&.ir{
			background-image: url("../img/icons/ir.svg");
		}
		&.ru{
			background-image: url("../img/icons/ru.svg");
		}
		&.us{
			background-image: url("../img/icons/us.svg");
		}
		&.es{
			background-image: url("../img/icons/es.svg");
		}
		&.in{
			background-image: url("../img/icons/in.svg");
		}
		&.id{
			background-image: url("../img/icons/id.svg");
		}
		&.ph{
			background-image: url("../img/icons/ph.svg");
		}
		&.ch{
			background-image: url("../img/icons/ch.svg");
		}
		&.vt{
			background-image: url("../img/icons/vt.svg");
		}
	}
}

.main-nav{
	clear: both;
	padding-top: 57px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 49px;

	.btn-wrap{
		position: relative;
		width: 164px;
		z-index: 0;
    margin-top: -19px;

		&:before{
			content: " ";
			position: absolute;
			border: 1px dashed rgba(255,255,255,0.18);
			top: -6px;
			right: 11px;
			bottom: -6px;
			left: 11px;
		}
	}

	.btn{
		background: #232E40;
    box-shadow: 10px 20px 20px rgba(0,0,0,0.1);
    display: block;

	  &:active{ 
    	box-shadow: 10px 20px 20px rgba(0,0,0,0.1), inset 0 10px 20px rgba(34,80,60,0.3);
	  }
	}
}

.menu{
	padding: 0;
	margin: 0 0 0 -20px;
	font-weight: 500;

	li{
		display: inline-block;
		vertical-align: middle;
		margin-right: 1px;

		&:before{
			content: none;
		}

		&>a, &>span{
			color: $btn-color;
			border: none;
			font-size: 14px;
			display: inline-block;
			padding: 9px 20px;
			border-radius: 10px;
 
			&.contest-btn{
				background: linear-gradient(190deg, #fda37e 0%, #e77728 100%);
				color: #FFF;
				font-weight: bold;
    		transition: transform 0.24s, background-color 0.24s, color 0.24s, border-color 0.24s, opacity 0.24s, box-shadow 0.24s, -webkit-transform 0.24s;

				&:hover{
					background: linear-gradient(190deg, #fda37e 0%, #e77728 100%);
    			transform: translateY(-1px) translateZ(0);
				}

				&:active {
				    box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.1), inset 0 10px 20px rgba(177, 112, 50, 0.3);
				}
			}
		}

		&>a:hover{
			background: rgba(0,0,0,0.2);
		}
	}

	&-toggle, &-close{
		display: none;
	}
}

.logo{
	white-space: normal;
	border: none;
	color: #F8FFFA;
	font-size: 35px;
	font-weight: 500;
  display: inline-block;

	img{
		display: inline-block;
		vertical-align: middle;
		margin-right: 25px;
		margin-top: -15px;
	}
}


@media screen and ( max-width: $breakpoints-xl ){

}


@media screen and ( max-width: $breakpoints-lg ){
	
  .header{
    .lang{
    	width: 160px;
    	text-align: center;
    	padding-top: 15px;

      ul{
        flex-wrap: wrap;
        justify-content: center;

        li{
        	margin: 0 2px 3px;
        }
      }
    }
  }

  .t-panel{
  	width: calc(100% - 160px);
  }

  .main-nav{
  	padding-top: 15px;
  }

	.menu{

		li{

			&>a, &>span{
				padding-left: 8px;
				padding-right: 8px;
			}
		}
	}
}


@media screen and (min-width: $breakpoints-md + 1 ){
	.header{
	}
}

@media screen and ( max-width: $breakpoints-md ){
	.header{
		.lang{
			float: none;
			padding: 22px 0 18px;
			width: auto;
			text-align: right;

			ul{
				display: inline-block;
				width: auto;
				margin: 0 0 0 20px;
			}
		}
	}

	.t-panel{
		width: 100%;
		border-radius: 0;
		float: none;
		padding: 10px 0;

		&:before{
			right: -24px;
			border-radius: 0;
		}
	}

	.balance{
		background: none;
		padding: 0;
		flex-basis: auto;

		&:before{
			content: none;
		}
	}

	.profit{
    margin-bottom: 3px;

		.next{
			margin-top: 7px;
		}
	}

	.menu{
		margin: 0;

		li{
			display: block;

			&>a, &>span{
				background: none;
				padding: 0;
				border-radius: 0;
				font-size: 16px;

				&.contest-btn{
					padding: 7px 15px;
					border-radius: 5px;
				}
			}
		}

		&-wrap{
			position: fixed;
			z-index: 1000;
			background: linear-gradient(to bottom,  $grad1_begin 0%, #358768 100%);
			top: 0;
			left: 0;
			bottom: 0;
			width: 240px;
			transform: translateX(-240px);
			padding: 60px 24px 60px 24px;
			transition: transform 0.4s, box-shadow 0.4s;

			.menu-opened &{
				box-shadow: 3px 0 60px rgba(0,0,0,0.3);
				transform: translateX(0);
			}
		}

		&-close{
			position: absolute;
			top: 10px;
			right: 16px;
			width: 32px;
			height: 32px;
			background: none;
			display: block;
			border: none;
			outline: none;

			&:before, &:after{
				content: " ";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 2px;
				height: 16px;
				background: $bg;
				transform: rotate(-45deg);
				margin: -8px -1px;
			}

			&:after{
				transform: rotate(45deg);
			}
		}

		&-toggle{
			display: inline-block;
			position: relative;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 500;
			background: none;
			border: none;
			padding: 10px 15px 10px 40px;
			border-radius: 7px;
    	background: linear-gradient(190deg, #FFDB62 0%, #F8A631 100%);
			color: $bg;
			outline: none;
			position: absolute;
			top: -56px;
			left: 0;

			&:before, &:after{
				content: " ";
				position: absolute;
				top: 50%;
				left: 15px;
				width: 16px;
				height: 6px;
				border-top: 2px solid #FFF;
				border-bottom: 2px solid #FFF;
				margin-top: -6px;
			}
			&:after{
				margin-top: -2px;
			}
		}
	}
}

@media screen and ( max-width: $breakpoints-sm ){
	.header{
	}

	.t-panel{
		padding-right: 170px;
		display: block;
    line-height: normal;

		.btn{
			position: absolute;
			top: 50%;
			right: 0;
    	transform: translateY(-50%);
		}
	}

	.profit{
		margin: 5px 0 0 0;

		.next{
			margin: 0;
		}
	}

	.balance{
		margin: 0;
	}

	.hstats{
		display: inline-block;
		border: 1px dashed #B1B1B1;
		border-radius: 5px;
		padding: 6px 30px 6px 10px;
		position: relative;


		&:before{
			content: " ";
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
			width: 39px;
			height: 41px;
			background: url("../img/icons/badge.svg") no-repeat 50% 50%;
			background-size: contain;
		}
	}
}

@media screen and ( max-width: $breakpoints-s ){
	.header{
	}

	.lang-title{
		display: none;
	}

	.hstats{
		border: none;
		display: block;
		padding: 0;
		margin: 0;
		text-align: center;

		&:before{
			top: 20px;
			right: 10px;
		}
	}
}

@media screen and ( max-width: $breakpoints-xs ){
	.header{
		.lang{
	    width: 185px;
	    text-align: right;
	    margin-left: auto;
	  }
	}

	.t-panel{
		padding-right: 0;

		.btn{
			position: relative;
			transform: none;
			margin: 10px 0 0 0;
	    padding: 9px 11px;
	    display: block;
	    min-width: 0;
		}
	}

	.menu-toggle{
		overflow: hidden;
		text-indent: 100px;
		width: 46px;
		padding: 10px;
    top: -68px;
	}
}


@media screen and ( max-width: 360px ){
	.header{
	}
}
