.svgicon {
	display: inline-block;
  vertical-align: top;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.svgicon-arrow {
	font-size:(10.543/10)*1rem;
	width:(9.298/10.543)*1em;
}
.svgicon-case {
	font-size:(18.066/10)*1rem;
	width:(18.031/18.066)*1em;
}
.svgicon-coin-minus {
	font-size:(28/10)*1rem;
	width:(28.1/28)*1em;
}
.svgicon-coin-plus {
	font-size:(28/10)*1rem;
	width:(28.1/28)*1em;
}
.svgicon-coin_down {
	font-size:(25.538/10)*1rem;
	width:(25.584/25.538)*1em;
}
.svgicon-coin_up {
	font-size:(25.538/10)*1rem;
	width:(25.584/25.538)*1em;
}
.svgicon-coinicon {
	font-size:(51.373/10)*1rem;
	width:(51.373/51.373)*1em;
}
.svgicon-copy {
	font-size:(18.424/10)*1rem;
	width:(15.074/18.424)*1em;
}
.svgicon-download {
	font-size:(10.006/10)*1rem;
	width:(11.992/10.006)*1em;
}
.svgicon-help {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.svgicon-internet {
	font-size:(24.817/10)*1rem;
	width:(24.817/24.817)*1em;
}
.svgicon-lock {
	font-size:(40.612/10)*1rem;
	width:(28.428/40.612)*1em;
}
.svgicon-mail {
	font-size:(24.03/10)*1rem;
	width:(30.606/24.03)*1em;
}
.svgicon-ok {
	font-size:(58.381/10)*1rem;
	width:(58.381/58.381)*1em;
}
.svgicon-pay-per-click {
	font-size:(28.016/10)*1rem;
	width:(28.016/28.016)*1em;
}
.svgicon-pie-chart {
	font-size:(26.027/10)*1rem;
	width:(26.027/26.027)*1em;
}
.svgicon-play {
	font-size:(13.903/10)*1rem;
	width:(12.915/13.903)*1em;
}
.svgicon-profit {
	font-size:(20.935/10)*1rem;
	width:(18.032/20.935)*1em;
}
.svgicon-ref {
	font-size:(20.3/10)*1rem;
	width:(20.3/20.3)*1em;
}
.svgicon-review {
	font-size:(26.027/10)*1rem;
	width:(26.028/26.027)*1em;
}
.svgicon-reward {
	font-size:(25.312/10)*1rem;
	width:(26.831/25.312)*1em;
}
.svgicon-tg {
	font-size:(24/10)*1rem;
	width:(24/24)*1em;
}
.svgicon-tg2 {
	font-size:(10.1/10)*1rem;
	width:(12/10.1)*1em;
}
.svgicon-time {
	font-size:(27.582/10)*1rem;
	width:(24.558/27.582)*1em;
}
.svgicon-tron {
	font-size:(46.4/10)*1rem;
	width:(41.3/46.4)*1em;
}
.svgicon-withdraw {
	font-size:(21/10)*1rem;
	width:(21/21)*1em;
}
