.trx, .contract, .bl, .tron, .bnb{
  display: inline-block;
  vertical-align: baseline;
  width: 50px;
  height: 1em;
  background-image: url("../img/icons/ch.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}


.main{
  &-section{
    background: linear-gradient(to bottom,  $grad1_begin 0%, $grad1_end 100%);
    color: #FFF;
    padding: 0 0 80px;
    position: relative;
    overflow: hidden;

    &:before{
      content: " ";
      position: absolute;
      bottom: -30px;
      left: 51.7%;
      width: 10000px;
      height: 116px;
      background: $bg;
      box-shadow: inset 10px 20px 20px rgba(0, 0, 0, 0.1);
      z-index: 2;
      margin-left: 78px;
    }

    &:after{
      content: " ";
      position: absolute;
      bottom: -39px;
      left: 51.7%;
      width: 102px;
      height: 125px;
      background: url("../img/background/main-decor.png") no-repeat 0 0;
      z-index: 1;
    }

    .decor{
      position: absolute;
      top: 50%;
      left: 0;
      width: 110%;
      height: 200%;
      transform: rotate(-5deg) translateY(-50%);
      background: url("../img/background/main-bg.png") repeat 0 0;
      margin-top: -19px;
      animation: main 8s infinite linear;
    }
  } 

  &-content{
    font-size: 15px;
    padding-right: 49%;
    line-height: normal;

    .logo{
      margin-bottom: 81px;
    }

    h1, .h1{
      margin-top: 0;
      margin-bottom: 50px;

      &+h2, &+.h2{
        margin-top: -50px;
      }


      .trx{
        background-image: url("../img/words/trx-30-600-FFFFFF.png");
        width: 60px;
        height: 23px;
        margin-bottom: -1px;
      }

      .contract{
        background-image: url("../img/words/contract-30-600-FFFFFF.png");
        width: 219px;
        height: 24px;
        margin-bottom: -1px;
      }

      .bl{
        background-image: url("../img/words/bl-30-700-FFFFFF.png");
        width: 158px;
        height: 24px;
        margin-bottom: -1px;
      }

      .bnb{
        background-image: url("../img/words/bnb-30-700-FFFFFF.png");
        width: 61px;
        height: 23px;
        margin-bottom: -1px;
      }
    }

    h2, .h2{
      font-size: 26px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 50px;
      color: inherit;
    }

    .bl{
      background-image: url("../img/words/bl-15-400-FFFFFF.png");
      width: 72px;
      height: 13px;
      margin-bottom: -1px;
    }
  }
}

@keyframes main {
  from {
    background-position: 0 0
  }

  to {
    background-position: 0 -324px;
  }
}

.block{
  background: #232E40;
  border-radius: 15px;
  margin: 0 0 16px;
  padding: 51px 44px 32px;
  position: relative;
  box-shadow: 10px 10px 70px rgba(0, 0, 0, 0.13); 

  &-title{
    color: $color3;
    font-size: 23px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 10px;
  }
}

.about{
  padding: 40px 0 85px;

  &-info{
    float: right;
    width: 38.5%;
    margin-top: -427px;
    position: relative;
    z-index: 5;

    &:before{
      content: " ";
      position: absolute;
      bottom: -16px;
      left: -50px;
      background: url("../img/background/grid1.png") no-repeat 0 0;
      width: 127px;
      height: 138px;
      z-index: -1;
    }

    .block{
      margin: 0 0 16px -25px;
      z-index: 5;
    }
  }

  &-content{
    padding-right: 46.2%;
    padding-top: 18px;
  }

  &-list{
    padding-top: 18px;
  }

  &-item{
    border: 1px dashed rgba(14, 21, 34, 1);
    border-radius: 10px;
    padding: 13px 25px 15px 37px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 17px;
    margin-left: 19px;
    position: relative;
    color: #8190A7;

    &:before{
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;
      width: 40px;
      height: 40px;
      margin: -20px;
      background: linear-gradient(135deg, #FCD17A 0%, #E6A723 100%);
      border-radius: 50%;
      box-shadow: 5px 10px 10px rgba(223, 153, 6, 0.10);
    }

    &:after{
      content: " ";
      position: absolute;
      top: 50%;
      left: -4px;
      width: 11px;
      height: 10px;
      margin-top: -5px;
      background: url("../img/icons/arrow.svg") no-repeat 0 0;
    }

    strong{
      color: inherit;
      font-weight: 500;
    }

    .trx{
      background-image: url("../img/words/trx-15-400-8190A7.png");
      width: 28px;
      height: 13px;
      margin-bottom: -1px;
    }

    .bnb{
      background-image: url("../img/words/bnb-14-400-8190A7.png");
      width: 27px;
      height: 10px;
    }

    .contract{
      background-image: url("../img/words/contract-15-400-8190A7.png");
      width: 103px;
      height: 13px;    
      margin-bottom: -1px;  
    }
  }
}

.daily{
  padding: 36px 40px 38px 102px;
  color: #FFF;
  position: relative;
  border-radius: 5px;
  background-image: repeating-linear-gradient(0deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52) 8px, transparent 8px, transparent 13px, rgba(255, 255, 255, 0.52) 13px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52) 8px, transparent 8px, transparent 13px, rgba(255, 255, 255, 0.52) 13px), repeating-linear-gradient(180deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52) 8px, transparent 8px, transparent 13px, rgba(255, 255, 255, 0.52) 13px), repeating-linear-gradient(270deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52) 8px, transparent 8px, transparent 13px, rgba(255, 255, 255, 0.52) 13px);
  background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  margin-bottom: -6px;
  min-height: 178px;
  line-height: normal;
  z-index: 5; 

  &-num{
    position: absolute;
    top: 40px;
    left: -61px;
    height: 114px;
    width: 123px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 26px;
    font-weight: bold;
    background: linear-gradient(190deg,  #666F7E 0%, #1D2636 100%);
    border-radius: 24px;
    box-shadow: -28px -28px 0 rgba(248,166,49,0.06), 19px 22px 0 rgba(248,166,49,0.06), 0 0 3px rgba(248,166,49,0.06);

    &:before{
      content: " ";
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      border: 2px dashed rgba(255,255,255,0.35);
      border-radius: 16px;
    }

    .svgicon:nth-child(1){
      position: absolute;
      top: -10px;
      left: -5px;
      width: 42px;
      height: 47px;
    }

    .svgicon:nth-child(2){
      position: absolute;
      bottom: -10px;
      right: -8px;
      width: 34px;
      height: 37px;
      opacity: 0.2;
    }
  }

  &-content{
    font-size: 18px;
    font-weight: 500;
  }

  &-capt{
    font-size: 14px;
    font-weight: normal;
    margin-top: 9px;

    .trx{
      background-image: url("../img/words/trx-14-400-FFFFFF.png");
      width: 25px;
      height: 12px;
      margin-bottom: -1px;
    }

    .contract{
      background-image: url("../img/words/contract-14-400-FFFFFF.png");
      width: 96px;
      height: 12px;    
      margin-bottom: -1px;  
    }

    .bnb{
      background-image: url("../img/words/bnb-14-400-FFFFFF.png");
      width: 27px;
      height: 10px;
    }
  }

  &-val{
    font-size: 25px;
    font-weight: 600;
    margin-top: 19px;

    .trx{
      background-image: url("../img/words/trx-25-600-FFFFFF.png");
      width: 48px;
      height: 19px;
      margin-bottom: -1px;
    }

    .trx{
      background-image: url("../img/words/bnb-25-600-FFFFFF.png");
      width: 50px;
      height: 19px;
      margin-bottom: -1px;
    }
  }
}


.input{
  border: 1px solid $borders;
  padding: 21px 82px 21px 17px;
  width: 100%;
  display: block;
  border-radius: 14px; 
  font-size: 14px;
  transition: border-color 0.23s;
  background: transparent;
  color: #FFF;

  outline: none!important;

  &:focus{
    border-color: $color2;
  }

  &::placeholder{
    color: #FFF;
  }
}

.calc{
  &-top{
    margin-top: 44px;
    position: relative;
    padding-right: 102px;
    margin-bottom: 54px;
  }

  &-input{
    position: relative;

    .label{
      height: 25px;
      border-radius: 13px; 
      padding: 2px 18px;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #FFF;
      background: linear-gradient(190deg,  #FCD37D 30%, #DF9906 100%);
      position: absolute;
      top: 18px;
      right: 16px;
    }

    .input{
      background: #1E2838;
    }

    .trx{
      background-image: url("../img/words/trx-10-600-FFFFFF.png");
      width: 20px;
      height: 8px;
      margin-bottom: 1px;
    }

    .bnb{
      background-image: url("../img/words/bnb-10-600-FFFFFF.png");
      width: 21px;
      height: 8px;
      margin-bottom: 1px;
    }
  }

  &-time{
    position: absolute;
    top: -7px;
    right: 0;
    width: 70px;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    color: #FFF;

    .svgicon{
      width: 25px;
      height: 28px;
      color: $color2;
      margin-bottom: 8px;
    }
  }
}

.table{
  color: $text-accent;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
    margin-bottom: 28px;

  &-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 26px;
    align-items: center;

    &:last-child{
      margin-bottom: 0;
    }

    .badge{
      background: #1A2433;
      border-radius: 5px;
      padding: 6px 20px;
      color: #A9ADB2;
      font-size: 12px;
      display: inline-block;
      text-align: center;

      .trx{
        background-image: url("../img/words/trx-12-400-A9ADB2.png");
        width: 22px;
        height: 9px;
      }

      .bnb{
        background-image: url("../img/words/bnb-12-400-A9ADB2.png");
        width: 22px;
        height: 9px;
      }
    }
  }
}

.stats{
  color: #8190A7;
  font-size: 14px;
  font-weight: normal;
  margin: -33px 0 -22px;

  &-label{
    color: #D3D3D3;
    font-size: 20px;
    font-weight: 500;
    word-break: break-word;

    .cur{
      color: $color2;
      font-size: 14px;
      font-weight: normal;
    }

    .trx{
      background-image: url("../img/words/trx-14-400-DF9906.png");
      width: 25px;
      height: 10px;
      margin-bottom: 1px;
    }

    .bnb{
      background-image: url("../img/words/bnb-14-400-DF9906.png");
      width: 27px;
      height: 10px;
      margin-bottom: 1px;
    }
  }

  &-row{
    margin-left: -44px;
    margin-right: -44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18px 0;
    background: url("../img/background/shadow.png") no-repeat 0 0;

    &:first-child{
      background: none;
    }
  }

  &-item{
    width: 50%;
    padding: 15px 38px 15px 44px;
    position: relative;

    &:first-child{
      padding-right: 20px
    }

    &:last-child{
      &:before{
        content: " ";
        position: absolute;
        top: 19px;
        left: 4px;
        height: 10px;
        border-left: 1px solid #D8D8D8;
      }
    }

    &.btn-item{
      padding-left: 8px;
      margin-top: 3px;

      &:before{
        content: none;
      }
    }
  }

  .btn{
    background: #F0BA4A;
  }
}


.company{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  color: #8190A7;
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  max-width: 572px;

  &-logo{
    width: 76px;
    flex: 0 0 76px;
    margin-right: 4px;
    padding-top: 11px;

    img{
      max-width: 46px;
    }
  }

  &-text{
    flex: 1 1 auto;

    .contract{
      background-image: url("../img/words/contract-15-400-8190A7.png");
      width: 103px;
      height: 13px;    
      margin-bottom: -1px;  
    }
  }

  &-opt{
    margin-left: 20px;
    flex: 0 0 140px;

    .btn{
      display: block;
      min-width: 0;
      border-radius: 5px;
      padding: 10px 20px;
    }
  }

  &-title{
    color: $color3;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &.company-uk{

    .company-title{
      color: $color3;
    }
  }
}



.why{
  font-weight: normal;

  &-columns{
    display: flex;
    margin-left: -80px;
    padding-top: 51px;
    margin-bottom: 87px;

    .column{
      flex: 0 0 32%;
      padding-left: 80px;
      position: relative;

      &:before{
        content: " ";
        position: absolute;
        top: 90px;
        left: 28px;
        border-left: 1px solid #707070;
        opacity: 0.19;
        height: 108px;
      }

      &:first-child{
        &:before{
          content: none;
        }
      }
    }

    .diagram-column{
      flex-basis: 36%;
      padding-left: 70px;
    }
  }

  &-icon{
    margin-bottom: 25px;
  }

  &-title{
    margin-top: 0;
    color: $color3;
    margin-bottom: 32px;
  }

  &-content{
    font-size: 14px;
    line-height: normal;

    .contract{
      background-image: url("../img/words/contract-14-400-FFFFFF.png");
      width: 96px;
      height: 12px;    
      margin-bottom: -1px;  
    }

    .bl{
      background-image: url("../img/words/bl-14-400-DEDEDE.png");
      width: 67px;
      height: 12px;
      margin-bottom: -1px;
    }
  }
}


.diagram{
  width: 216px;
  margin: 0 auto 55px;
  position: relative;

  &-main{
    img{
      display: block;
      margin: 0 auto;
    }
    #chart{
      display: block;
      margin: 0 auto;    
      width: 216px;
      height: 216px; 
    }
  }


  &-capt{
    width: 110px;
    text-align: center;
    position: absolute;
    top: 80px;
    left: 50%;
    margin-left: -55px;
    font-size: 10px;
    font-weight: normal;
    color: #EDEDED;
    line-height: normal;

    span{
      font-size: 25px;
      font-weight: 500;
      color: #F0BA4A;
      display: block;
      margin-bottom: 5px;
    }
  }
}

.legend{

  td:last-child{
    text-align: right;
  }
}


.code-block{
  position: relative;
  margin-bottom: 66px;

  &:before{
    content: " ";
    position: absolute;
    top: 0;
    right: 50%;
    margin-right: -320px;
    left: 0;
    height: 178px;
    background: linear-gradient(to bottom, #F6C663 0%, #E4A31B 100%);
    border-radius: 0 33px 58px 0;
    box-shadow: 10px 20px 20px rgba(35,59,125,0.2);
  }

  &:after{
    content: " ";
    position: absolute;
    top: 0;
    right: 50%;
    left: 0;
    height: 178px;
    border-radius: 0 0 58px 0;
    background: url("../img/background/bg-line.png") no-repeat 100% 22px;
    margin-right: 137px;
  }

  &-right{
    float: right;
    width: 48.2%;
    font-size: 14px;
    line-height: 1.5em;
    color: #FFF;

    .video{
      margin-top: -50px;
      margin-left: -20px;
    }
  }

  &-left{
    float: left;
    width: 47%;
    padding-top: 33px;
    position: relative;
    z-index: 5;

    &:before{
      content: " ";
      position: absolute;
      top: -21px;
      right: 29%;
      width: 48px;
      height: 46px;
      background: url("../img/background/grid3.png") no-repeat 0 0;
    }

    &:after{
      content: " ";
      position: absolute;
      bottom: -20px;
      right: -30px;
      z-index: -1;
      background: url("../img/background/grid1.png") no-repeat 0 0;
      width: 127px;
      height: 138px;
    }
  }

  &-title{
    color: #FFF;
    padding-left: 35px;
    font-size: 18px;
    padding-right: 140px;
    position: relative;
    margin-bottom: 27px;

    img{
      display: inline-block;
      vertical-align: middle;
      margin: 0 16px 0 0;
    }

    .copy{
      background: none;
      color: inherit;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 4px;
      right: 39px;
      border: none;
      font-size: 14px;
      outline: none;
      cursor: pointer;
      border-bottom: 1px dashed transparent;
      transition: border 0.24s;
      color: #86641D;

      &:hover{
        border-bottom-color: #86641D;
      }
    }
  }
}

.code{

  &-wrap{
    max-height: 280px!important;

    pre.language-javascript{
      width: 1400px;

    }
  }

  &-panel{
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
    border-radius: 15px;
    background: #232E40;
    padding: 2px 2px 2px 12px;
    position: relative;
    z-index: 10;

    &.code-img{
      padding: 0;
      display: block;
      border: none;
      transition: box-shadow 0.24s;

      img{
        display: block;
      }

      &:hover{
        box-shadow: 0 20px 40px rgba(0,0,0,0.2);

      }
    }
  }
}

pre[class*="language-"],
code[class*="language-"]{
  &::selection{
    color: $bg;
    background: $color2;
  } 
}

.language-javascript{
  background: none!important;
  font-size: 14px!important;
  line-height: normal!important;
  text-shadow: none!important;
  color: #D3D3D3!important;
}

.line-numbers .line-numbers-rows{
  border: none;
  color: #EEB945;
}

.line-numbers-rows > span:before{
  color: #EEB945;
}

.video{
  position: relative;
  width: 273px;
  height: 273px;
  display: block;
  background: url("../img/background/pane.png") no-repeat 0 0;
  margin-bottom: 29px;
  border: none;
  box-shadow: 10px 10px 20px rgba(0,0,0,0.1);
  border-radius: 19px 19px 79px 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFF;
  font-size: 16px;
  text-align: center;
  transition: box-shadow 0.24s, transform 0.24s;
  line-height: normal;

  &-text{    
    padding-top: 10px;
    font-weight: normal;
    color: #DEDEDE;

    .trx{
      background-image: url("../img/words/trx-14-400-DEDEDE.png");
      width: 25px;
      height: 10px;
    }

    .contract{
      background-image: url("../img/words/contract-14-400-DEDEDE.png");
      width: 100px;
      height: 12px;
      margin-bottom: -1px;
    }

    .bl{
      background-image: url("../img/words/bl-14-400-DEDEDE.png");
      width: 67px;
      height: 12px;
      margin-bottom: -1px;
    }

    .tron{
      background-image: url("../img/words/tron-14-400-DEDEDE.png");
      width: 27px;
      height: 11px;
      margin-bottom: -1px;
    }

    .bnb{
      background-image: url("../img/words/bnb-14-400-DEDEDE.png");
      width: 27px;
      height: 10px;
    }

  }

  img{
    display: block;
    margin: 0 auto 5px;
  }

  strong{
    font-size: 20px;
    font-weight: 600;
    color: inherit;
    display: block;
    margin-bottom: 1px;
  }

  .play-btn{
    font-size: 12px;
    display: block;
    font-weight: 500;
    margin-top: 28px;

    .svgicon{
      display: inline-block;
      vertical-align: middle;
      margin: -2px 2px 0 0 ;
      width: 14px;
    }
  }

  &:hover{
    box-shadow: 10px 10px 30px rgba(0,0,0,0.2);
    transform: translateY(-1px);
  }

  &:active{
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
    transform: translateY(1px);
  }
}

.text{
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 46px;
  padding-top: 18px;
  line-height: normal;

  .trx{
    background-image: url("../img/words/trx-14-400-DEDEDE.png");
    width: 25px;
    height: 10px;
  }

  .bnb{
    background-image: url("../img/words/bnb-14-400-DEDEDE.png");
    width: 27px;
    height: 10px;
  }

  .contract{
    background-image: url("../img/words/contract-14-400-DEDEDE.png");
    width: 100px;
    height: 12px;    
    margin-bottom: -1px;  
  }
}

.conditions{

}

.cond{
  position: relative;
  color: #707070;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 83px;

  &-main{
    float: left;
    width: 29.1%;
    border-radius: 20px 20px 40px 20px;
    padding: 42px 25px 42px 37px;
    color: #FFFFFF;
    position: relative;
    z-index: 5;
    min-height: 269px;
    margin-right: -20px;
    line-height: normal;

    &:before, &:after{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: inherit;
    }

    &:before{
      background: linear-gradient(210deg,  #F2C458 0%, #E3A411 100%);      
    }
  }

  &-stat{
    position: relative;
    padding: 0 0 40px 70px;
    font-size: 20px;
    line-height: normal;
    font-weight: normal;
    margin-bottom: 39px;

    strong{
      font-size: 30px;
      font-weight: normal;
      display: block;
      margin-bottom: 5px;
      color: inherit;
    }

    .svgicon{
      position: absolute;
      top: 11px;
      left: 0;
      width: 46px;
      height: 48px;
    }

    &:before{
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 59px;
      border-bottom: 2px solid rgba(255,255,255,0.29);
    }
  }

  &-content{
    overflow: hidden;
  } 
}

.pref{
  &-list{
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 2px dashed #091322;
    background: rgba(25,34,49,0.75);
    border-left: none;
    padding: 26px 10px 26px 64px;
    border-radius: 0 20px 40px 0;
  }

  &-item{
    padding-right: 20px;
  }

  &-icon{
    color: #F2C358;
    margin-bottom: 15px;
    margin-left: -8px;

    .svgicon{
      height: 26px;
    }
  }

  &-title{
    font-size: 16px;
    margin-bottom: 15px;
  }

  &-val{
    color: #DF9906;
    font-size: 20px;
    font-weight: 500;

    span{
      font-size: 15px;
    }

    .trx{
      background-image: url("../img/words/trx-20-500-DF9906.png");
      width: 38px;
      height: 16px;
      margin-bottom: -1px;
    }

    .bnb{
      background-image: url("../img/words/bnb-20-500-DF9906.png");
      width: 39px;
      height: 16px;
      margin-bottom: -1px;
    }
  }

  &-capt{
    font-weight: 500;

    span{
      font-size: 14px;
      font-weight: normal;
      color: #DF9906;
    }
  }
}

.advantages{
  padding-left: 63px;
  padding-top: 21px;

  .item{
    display: inline-block;
    vertical-align: middle;
    margin-right: 27px;
    padding-right: 30px;
    position: relative;

    &:after{
      content: " ";
      position: absolute;
      top: -4px;
      right: 0;
      height: 32px;
      border-right: 1px solid rgba(112,112,112,0.19);
    }

    &:last-child{
      border-right: none;
      margin-right: 0;
      padding-right: 0;


      &:after{
        content: none;
      }

    }

    .svgicon{
      color: #F2C358;
      width: 21px;
      height: 21px;
      vertical-align: middle;
      margin-right: 17px;
      margin-top: -4px;
    }
  }
} 

.how{

  &-content{
    margin-top: 67px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -58px;
    position: relative;
    z-index: 5;

    .how-block{
      width: 50%;
      padding-left: 58px;
      margin-bottom: 30px;

      .block{
        min-height: 100%;
        padding: 61px 37px 25px;
        position: relative;
        border-radius: 20px 20px 40px 20px;

        &:before{
          content: "";
          position: absolute;
          width: 20px;
          top: 40px;
          bottom: 77px;
          left: -8px;
          background: linear-gradient(135deg, #FCD17A 0%, #E6A723 100%);
          border-radius: 15px;
          z-index: -1;
        }

        &:after{
          content: " ";
          position: absolute;
          width: 48px;
          height: 46px;
          top: -23px;
          right: 7.5%;
          background: url("../img/background/grid3.png") no-repeat 0 0;
        }
      }

      .table{
        .label{
          color: #D3D3D3;
          font-size: 16px;
          padding-right: 20px;
          font-weight: 500;

          .trx{
            background-image: url("../img/words/trx-16-500-D3D3D3.png");
            width: 30px;
            height: 13px;
            margin-bottom: -1px;
          }

          .contract{
            background-image: url("../img/words/contract-16-500-D3D3D3.png");
            width: 145px;
            height: 13px;
            margin-bottom: -1px;
          }

          .tron{
            background-image: url("../img/words/tron-16-500-D3D3D3.png");
            width: 44px;
            height: 13px;
            margin-bottom: -1px;
          }

          .bnb{
            background-image: url("../img/words/bnb-16-500-D3D3D3.png");
            width: 31px;
            height: 13px;
            margin-bottom: -1px;
          }
        }

        .btn{
          padding: 10px 15px;
          text-transform: none;
          min-width: 136px;
          border-radius: 5px;
          color: #99A3BC;
          font-weight: 500;

          .trx{
            background-image: url("../img/words/trx-12-400-99A3BC.png");
            width: 21px;
            height: 9px;
          }

          .bnb{
            background-image: url("../img/words/bnb-12-400-99A3BC.png");
            width: 22px;
            height: 9px;
          }
        }

        &-row{
          margin-bottom: 18px;
        }
      }
    }
  }

  &-title{
    color: #EEB744;
    font-size: 22px;
    font-weight: 500;
    padding-left: 62px;
    position: relative;
    margin-bottom: 59px;

    .num{
      position: absolute;
      top: 50%;
      left: 17px;
      width: 40px;
      height: 40px;
      margin: -20px;
      background: linear-gradient(135deg, #FCD17A 0%, #E6A723 100%);
      border-radius: 50%;
      box-shadow: 5px 10px 10px rgba(240, 186, 74, 0.10);
      color: #FFF;
      line-height: 40px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &-form, .wallet{
    margin-left: -37px;
    margin-right: -37px;
    padding-left: 37px;
    padding-right: 37px;
    margin-top: 33px;
    padding-top: 41px;
    background: url("../img/background/shadow.png") no-repeat 0 0;

    h4, .h4{
      font-weight: 500;
      color: #EEB744;

      .trx{
        background-image: url("../img/words/trx-18-500-EEB744.png");
        width: 34px;
        height: 14px;
        margin-bottom: -1px;
      }

      .bnb{
        background-image: url("../img/words/bnb-18-500-EEB744.png");
        width: 35px;
        height: 14px;
        margin-bottom: -1px;
      }
    }

    .btn-2{
      background: #656D7B;
    }
  }

  &-buttons{
    display: flex;
    margin-left: -20px;

    &>div{
      padding-left: 20px;
      width: 50%;
    }

    .btn{
      display: block;
      width: 100%;
    }

    .btn-stats{
    }
    
    .btn-withdraw{
      border-color: #CB9F9F;
      color: #C18787;
    }
  }
}

.deposit{
  &-input{
    position: relative;
    margin-top: 28px;

    .input{
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 15px 90px 15px 0;
      border-radius: 0;
    }

    .label{      
      position: absolute;
      top: 11px;
      right: 0;
      height: 25px;
      border-radius: 13px;
      padding: 2px 18px;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #FFFFFF;
      background: linear-gradient(190deg,  #FCD37D 30%, #DF9906 100%);

      .trx{
        background-image: url("../img/words/trx-10-600-FFFFFF.png");
        width: 20px;
        height: 8px;
        margin-bottom: 1px;
      }


      .bnb{
        background-image: url("../img/words/bnb-10-600-FFFFFF.png");
        width: 21px;
        height: 8px;
        margin-bottom: 1px;
      }
    }
  }

  &-capt{
    color: #99A3BC;
    font-weight: normal;
    margin: 10px 0 48px;
    font-size: 14px;


    .trx{
      background-image: url("../img/words/trx-14-400-99A3BC.png");
      width: 25px;
      height: 10px;
      margin-bottom: -1px;
    }


    .bnb{
      background-image: url("../img/words/bnb-14-400-99A3BC.png");
      width: 27px;
      height: 10px;
      margin-bottom: -1px;
    }
  }
}

.arr-list{
  padding: 4px 0 0;
  margin: 0 0 30px;
  font-size: 15px;
  font-weight: normal;

  li{
    position: relative;
    padding-left: 30px;
    margin-bottom: 33px;

    &:before{
      content: ' ';
      position: absolute;
      top: 6px;
      left: -4px;
      width: 11px;
      height: 10px;
      background: url("../img/icons/arr.svg") no-repeat 0 0;
    }
  }
}

.wallet{
  .svgicon{
    width: 28px;
    height: 28px;
    margin: 15px 0 16px;
    color: #F2C358;
  }

  .adress{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .val{
    color: #EEB744;      
  }
}

.modal{

  &-body{    
    box-shadow: 5px 5px 40px rgba(38,64,133,0.1);
    border-radius: 10px;
    padding: 38px 41px;
    width: 495px;
    font-size: 14px;
    background: #232E40;
    color: #8190A7;
    position: relative;
    max-width: 100%;

    &-wrap{
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; 
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0 70px;
    } 

    &-scroll{ 
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;  
      padding: 70px 6px;
    }
  }
  
  .btn{
    background: #F0BA4A;
  }

  .btn-2{
    min-width: 103px;
    background: #656D7B;
  }

  .btn-cancel{
    border-color: #EE4444;
    background: #EE4444; 
  }

  .btn-bordered{
    border: 1px solid $borders;
    color: #99A3BC;    
    background: transparent;
  }
  
  .trx{
    background-image: url("../img/words/trx-12-400-8190A7.png");
    width: 22px;
    height: 9px;
  }
  
  .bnb{
    background-image: url("../img/words/bnb-12-400-8190A7.png");
    width: 22px;
    height: 9px;
  }

  &-title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 50px;
    position: relative;
    color: $color;

    &.with-icon{      
      padding-left: 62px;
      position: relative;
    }

    .btn{
      font-size: 12px;
      min-width: 0; 
      margin-bottom: 20px;
      padding: 10px 15px;
      width: auto;

      .svgicon-arrow{
        transform: rotate(180deg);
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
    }

    .trx, #how .modal-part .block & .trx{
      background-image: url("../img/words/trx-16-600-F7C765.png");
      width: 33px;
      height: 13px;
      margin-bottom: -1px; 
    }


    .bnb, #how .modal-part .block & .bnb{
      background-image: url("../img/words/bnb-16-500-F7C765.png");
      width: 33px;
      height: 13px;
      margin-bottom: -1px; 
    }
  }

  &-columns{
    margin-left: -45px;

    .column{
      padding-left: 45px;
      margin-bottom: 20px;
      float: left;
      width: 50%;
    }
  }

  .deposit-input{
    margin-top: 0;
  }

  .deposit-capt{
    margin-bottom: 35px;
    color: #99A3BC;

    .trx{
      background-image: url("../img/words/trx-14-400-99A3BC.png");
      width: 25px;
      height: 10px;
    }

    .bnb{
      background-image: url("../img/words/bnb-14-400-99A3BC.png");
      width: 27px;
      height: 10px;
    }
  }

  .arr-list{
    font-size: inherit;
    padding-top: 6px;
    color: #E2E2E3;

    li{
      position: relative;
      padding-left: 30px;
      margin-bottom: 36px;
      line-height: 1.35em;
    }

    .trx {
      background-image: url(../img/words/trx-14-400-E2E2E3.png);
      width: 27px;
      height: 10px;
      margin-bottom: -1px;
    }

    .tron {
      background-image: url(../img/words/tron-14-400-E2E2E3.png);
      width: 38px;
      height: 12px;
      margin-bottom: -1px;
    }

    .bl{
      background-image: url(../img/words/bl-14-400-E2E2E3.png);
      width: 67px;
      height: 12px;
    } 

    .bnb {
      background-image: url(../img/words/bnb-14-400-E2E2E3.png);
      width: 27px;
      height: 10px;
      margin-bottom: -1px;
    }

  }

  .stats{
    overflow: hidden;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 50px;

    &-row{
      margin-left: -41px;
      margin-right: -41px;
      justify-content: space-between;
      margin-top: 0;
      padding-bottom: 0;
    }

    &-item{
      padding-left: 41px;
      padding-right: 41px;
      width: auto;
      min-width: auto;

      &:last-child:before{
        content: none;
      }
    }

  }

  &-img{
    text-align: center;
    margin-bottom: 20px;
  }

  h5{
    color: #F7C765;
    font-weight: normal;
  }

  .contract{
    background-image: url("../img/words/contract-12-400-8190A7.png");
    width: 83px;
    height: 10px;    
    margin-bottom: -1px;  
  }
    
  .tron {
    background-image: url(../img/words/tron-12-400-8190A7.png);
    width: 24px;
    height: 10px;
    margin-bottom: -1px;
  }

  .bl{
    background-image: url(../img/words/bl-12-400-8190A7.png);
    width: 59px;
    height: 11px;
  } 
}


.modal-lg .modal-body{
  width: 948px;
}

.modal-sm .modal-body{
  width: 362px;

  .modal-close{
    right: 24px;
  }
}

.modal-double .modal-body{
  background: none;
  box-shadow: none;
  width: 948px;
  padding: 0;
  overflow: visible;
}

.modal-double{

  &-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.modal-part{  
  width: calc(50% - 5px);
  flex: 0 0 calc(50% - 5px);
  max-width: calc(50% - 5px);

  .block{
    box-shadow: 5px 5px 40px rgba(38,64,133,0.1);
    border-radius: 10px;
    padding: 38px 41px;
    font-size: 14px;
    background: #232E40;
    margin-bottom: 10px;

    &.not-auth{
      position: relative;

      .not-auth-decor{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 220px;
        height: 340px;
        overflow: hidden;
        border-radius: 0 0 10px 0;
        color: #717171;
        opacity: 0.05;

        .svgicon{
          width: 261px;
          height: 384px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

#how .modal-part .block{
  height: 100%;
  color: #FFF;


  .trx{
    background-image: url("../img/words/trx-14-400-FFFFFF.png");
    width: 25px;
    height: 12px;
    margin-bottom: -1px;
  }

  .bnb{
    background-image: url("../img/words/bnb-14-400-FFFFFF.png");
    width: 27px;
    height: 10px;
  }

  .contract{
    background-image: url("../img/words/contract-14-400-FFFFFF.png");
    width: 96px;
    height: 12px;    
    margin-bottom: -1px;  
  }
}

#stat{
  .modal-body{
    width: 997px;
    overflow: visible;
  }

  .block{
    font-size: 12px;
    line-height: 1.5em;
  }

  .modal-title{
    margin-bottom: 22px;
  }

  .table{
    margin-bottom: -17px;
  }

  .table-row{
    margin-bottom: 15px;




    &.with-shadow{
      box-shadow: 5px 20px 30px rgba(0,0,0,0.1);
      border-radius: 0 0 20px 10px;
      margin: 0 -41px 28px;
      padding: 0 41px 25px;
      width: auto;
    }
  }
}

.fancybox-bg{
  background: #000;
}

.fancybox-is-open .fancybox-bg{
  opacity: 0.6;

}

.fancybox-slide--html .fancybox-close-small{
  top: 24px;
  right: 40px;
  color: #FFF;
  opacity: 0.47;
  transition: opacity 0.24s;

  &:hover{
    opacity: 1;
  }
}

.important{
  background: #1E2939;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #FFF;
  font-size: 12px;
  font-weight: normal;
  padding: 25px;
  line-height: normal;

  &:last-child{
    margin-bottom: 0;
  }

  strong{
    font-weight: 500;
    color: #F7C765;
  }

  p{
    margin-bottom: 1em;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .trx, #how .modal-part .block & .trx{
    background-image: url("../img/words/trx-12-400-FFFFFF.png");
    width: 22px;
    height: 9px;
    margin-bottom: 0;
  }

  .bnb, #how .modal-part .block & .bnb{
    background-image: url("../img/words/bnb-12-400-FFFFFF.png");
    width: 22px;
    height: 9px;
    margin-bottom: 0;
  }

  .contract, #how .modal-part .block & .contract{
    background-image: url("../img/words/contract-12-400-FFFFFF.png");
    width: 83px;
    height: 10px;    
    margin-bottom: -1px;  
  }


  .bl, #how .modal-part .block & .bl{
    background-image: url("../img/words/bl-12-400-FFFFFF.png");
    width: 59px;
    height: 11px;    
    margin-bottom: -1px;  
  }

}

.thx, .subscribe{
  &-text{
    text-align: center;
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 1.5em;

    strong{
      color: $color;
      font-weight: normal;
    }
    .contract{
      background-image: url("../img/words/contract-12-400-8190A7.png");
      width: 83px;
      height: 10px;    
      margin-bottom: -1px;  
    }

    .tron {
      background-image: url(../img/words/tron-12-400-8190A7.png);
      width: 24px;
      height: 10px;
      margin-bottom: -1px;
    }

    .bl{
      background-image: url(../img/words/bl-12-400-8190A7.png);
      width: 59px;
      height: 11px;
    } 
  }

  &-icon{
    margin-bottom: 16px;
    color: $color;

    .svgicon{
      width: 58px;
      height: 58px;
    }
  }

  &-title{
    color: $color;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }


  &-form{
    .input{
      background: #1E2838;
    }
  }

}

.subscribe{

  &-text{
    font-size: 14px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    color: #FFF;
  }

  &-form{

    .input-wrap{
      margin-bottom: 34px; 

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.question{  
  position: absolute;
  top: 50%;
  left: 17px;
  width: 40px;
  height: 40px;
  margin: -20px;
  background: linear-gradient(135deg, #FCD17A 0%, #E6A723 100%);
  border-radius: 50%;
  box-shadow: 5px 10px 10px rgba(223, 153, 6, 0.10);
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.witem{
  display: flex;
  align-items: center;
  background: url("../img/background/shadow.png") no-repeat 0 100%;
  padding: 51px 41px;
  margin-left: -41px;
  margin-right: -41px;

  &-icon{
    width: 25px;
    flex: 0 0 25px;
    max-width: 25px;
    margin-right: 22px;
    color: $grad1_end;

    .svgicon{
      width: 25px;
      height: 25px;
    }
  }


  &-content{
    flex: 1 1 auto;
    font-size: 14px;
    color: #8190A7;
  }

  &-title{
    font-size: 15px;
    color: $grad1_end;
    word-break: break-word;
    margin-bottom: 6px;
  }

  .trx{
    background-image: url("../img/words/trx-14-400-8190A7.png");
    width: 26px;
    height: 10px;
  }

  .bnb{
    background-image: url("../img/words/bnb-14-400-8190A7.png");
    width: 27px;
    height: 10px;
  }

  &-amount{
    flex: 1 1 auto;
    text-align: right;
    padding: 0 15px;

    .amount{
      font-size: 15px;
    }

    .cur{
      font-size: 14px;
    }
  
    .witem & .trx{
      background-image: url("../img/words/trx-14-400-EEB744.png");
      width: 26px;
      height: 10px;
    }
  
    .witem & .bnb{
      background-image: url("../img/words/bnb-14-400-EEB744.png");
      width: 27px;
      height: 10px;
    }
  }

  &-opts{
    text-align: right;
    width: 60px;
    flex: 0 0 60px;

    .info-icon{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
    }
  }

  &-copy{
    border: none;
    background: none;
    width: 20px;
    height: 20px;
    padding: 0;
    color: #6D778E;
    cursor: pointer;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;

    .svgicon{
      width: 16px;
      height: 18px;
    }

    &:hover{
      color: $color2;
    }

    &.done{
      &:before{
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        background: $bg url("../img/icons/ok.svg") no-repeat 0 0;
        margin: -10px;
        background-size: contain;
      }
    }
  }

  &.ref-witem{
    padding-top: 20px;
    padding-bottom: 30px;

    &:last-child{
      background: none;
    }
  }
}

.wd-item{
  display: flex;
  align-items: center;
  padding: 21px 41px 36px;
  margin-left: -41px;
  margin-right: -41px;
  color: #FFFFFF;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);

  &-content{
    flex-grow: 1;
    font-size: 12px;

    .sum{
      color: $grad1_end;
      font-size: 20px;
      margin: 14px 0 5px;

      .trx{
        background-image: url("../img/words/trx-20-400-EEB744.png");
        width: 37px;
        height: 16px;
      }

      .bnb{
        background-image: url("../img/words/bnb-20-400-EEB744.png");
        width: 39px;
        height: 15px;
      }
    }

    .eq{
      font-size: 16px;
      margin-top: 7px;
      color: #8190A7;
    }
  }

  .btn{
    border-radius: 5px;
    padding: 11px 26px;
    min-width: 0;
    font-size: 14px;

    .svgicon{
      width: 12px;
      height: 10px;
      margin-right: 10px;
      margin-top: 2px;
    }
  }

  &.with-shadow{
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
    border-radius: 0 0 20px 10px;
  }
}

.with-info{
  position: relative;
  padding-right: 20px;
}

.info-icon{
  position: absolute;
  top: 50%;
  right: 0;
  width: 14px;
  height: 14px;
  margin-top: -7px;

  &>i{
    background: #5E7190;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    color: #222D3F;
    font-family: Arial, sans-serif;
    font-size: 10px;
    display: block;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
  }

  .comment{
    position: absolute;
    top: 50%;
    right: 100%;
    margin-right: 15px;
    width: 280px;
    padding: 16px;
    z-index: 10;
    font-size: 12px;
    background: $bg;
    box-shadow: 2px 3px 20px rgba(0,0,0,0.1);
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.001s 0.3s;
    border-radius: 7px;
    text-align: left;
    color: #FFF;

    .trx{
      background-image: url("../img/words/trx-12-400-FFFFFF.png");
      width: 22px;
      height: 9px;
    }

    .contract{
      background-image: url("../img/words/contract-12-400-FFFFFF.png");
      width: 83px;
      height: 10px;    
      margin-bottom: -1px;  
    }
    
    .tron {
      background-image: url(../img/words/tron-12-400-FFFFFF.png);
      width: 35px;
      height: 15px;
      margin-bottom: -3px;
    }

    .bl{
      background-image: url(../img/words/bl-12-400-FFFFFF.png);
      width: 59px;
      height: 11px;
    } 

    .bnb{ 
      background-image: url("../img/words/bnb-12-400-FFFFFF.png");
      width: 22px;
      height: 9px;
    }
  }

  &:hover, &.opened{
    .comment{
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s, visibility 0.001s;
    }
  }

  .trx{
    background-image: url("../img/words/trx-14-400-EEB744.png");
    width: 26px;
    height: 10px;
  }

  .bnb{
    background-image: url("../img/words/bnb-14-400-EEB744.png");
    width: 27px;
    height: 10px;
  }
}

.amount{
  font-size: 20px;
  font-weight: 500;
  color: #FFF;

  .cur{
    font-size: 14px;
    font-weight: normal;
    color: $color3;
  }
}

.stat-columns{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  justify-content: space-between;

  .col{
    padding-left: 15px;

    &:first-child{
      .comment{
        right: -320px
      }
    }
  }

  .witem{
    background: none;
    box-shadow: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.ref-witems{
  margin-top: 47px;
}

.rewards{
  margin-top: 40px;

  h4, .h4{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    color: $color;
  }

  &-item{

    .witem{
      padding: 20px 0;
      background: none; 
      margin-left: 0;
      margin-right: 0;  

      &-opts{
        flex-basis: auto;
        width: auto;
      }

      &-content{
        font-size: 13px;
      }
    }
  }

  &-btn{
    margin: 9px 0 18px;
  }
}

.ref{
  &-levels{
    margin-left: -41px;
    margin-right: -41px;
  }

  &-level{
    padding: 26px 41px;
    border-bottom: 1px solid rgba(0,0,0,0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;

    &:first-child{
      padding-top: 0;
    }

    &:last-child{
      padding-bottom: 0;
      border: none;
    }

    &-icon{
      margin-right: 20px;
    }

    &-content{
      flex-grow: 1;
      padding-right: 15px;

      strong{
        color: $grad1_end;
        font-weight: inherit;
      }
    }

    &-title{
      color: $text-accent;
      margin-bottom: 5px;
    }

    &-opts{
      .info-icon{
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
      }

      .btn{
        margin-left: 12px;
      }
    }
  }
}

.banners-select{
  background: url("../img/background/shadow.png") no-repeat 0 100%;
  padding: 41px 41px 35px;
  margin: 0 -41px;

  .select{
    border: 1px solid $entryborders;
    background: #1E2838;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border-radius: 14px;
    outline: none;
    color: #FFF;

    &:focus{
      border-color: $color;
    }
  }
}

#promo{
  .ref-witem{
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  }
}

.banners-btns{
  .btn{
    margin: 0 15px 10px 0;
  }
}

.banners-cont{
  display: none;
}

.banners-code{
  border: 1px dashed #151D2C;
  outline: none;
  padding: 20px;
  border-radius: 10px;
  min-height: 110px;
  height: 110px;
  color: #8190A7;
  font-size: 13px;
  line-height: 1.5em;
  margin: 20px 0 30px;
  background: rgba(25,36,49,0.3);

  &:focus{
    border-color: $color;
  }
}

.ref-total{
  background: $color;
  color: #FFF;
  padding: 5px 14px;
  border-radius: 5px;
  float: right;
  margin-left: 15px;
  font-size: 14px;
  font-weight: normal;
  margin-top: -5px;
}

.ref{
  &-list{
    
  }

  &-item{
    margin: 0 -41px;
    padding: 20px 41px 20px 66px; 
    position: relative;
    color: #FFF;

    &:before{
      content: " ";
      position: absolute;
      top: 50%;
      left: 41px;
      width: 12px;
      height: 17px;
      margin-top: -8px;
      background: url("../img/icons/refitem.svg") no-repeat 0 0;
      background-size: contain;
    }

    &-top, &-bottom{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &>div{
        padding-left: 15px;
      }
    }

    &-top{
      margin-bottom: 5px;
      color: $color;
      font-size: 15px;

      .date{
        color: #8190A7;
        font-size: 12px;
      }
    }

    &-bottom{
      font-size: 12px;

      .amout{
        color: $color;
      }
    }

    &+.ref-item{
      background: url("../img/background/shadow.png") no-repeat 0 0;    
    }

    .trx{
      background-image: url("../img/words/trx-12-400-F7C765.png");
      width: 22px;
      height: 9px;
    }

    .bnb{
      background-image: url("../img/words/bnb-12-400-F7C765.png");
      width: 22px;
      height: 9px;
    }
  }
}

#ref .modal-title{
  margin-bottom: 30px;
}

.pageNumbers{
  cursor: default;

   a{
    border: 1px solid #707070;
    background: #707070;
    color: #FFF;
    text-align: center;
    width: 37px;
    height: 35px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: top;
    line-height: 35px;
    margin-right: 10px;
    margin-bottom: 10px;

    &.active{
      border-color: $color;
      background: $color;
      color: #FFF;
    }
  }
}

.pager{
  position: relative;
  padding-right: 100px;
  margin-top: 30px;
}

.previousPage, .nextPage{
  color: #FFF;
  position: absolute;
  top: 0;
  right: 0;
  width: 37px;
  height: 35px;

  &:hover{
    color: $color;
  }

  .svgicon{
    width: 7px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
  }
}

.previousPage{
  right: 45px;

  .svgicon{
    transform: rotate(180deg) translate(-50% , 50%);
  }
}

.invite{
  margin: 0 auto;
  padding: 60px 0 30px;
  max-width: 358px;
  text-align: center;

  img{
    margin-bottom: 46px;
  }

  &-title{
    font-size: 16px;
    margin-bottom: 28px;
    font-weight: 500;
  }

  &-deposit{
    font-size: 14px;
    text-align: center;
    padding: 63px 0;
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;

    &-icon{
      margin-bottom: 20px;
      color: #FFA536;

      .svgicon{
        width: 29px;
        height: 41px;
      }
    }
  }
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before{
  background: transparent;
}

#chartjs-tooltip{
  border-radius: 5px!important;
  padding: 7px 10px!important;
  color: #FFF!important;
  box-shadow: 1px 2px 10px rgab(0,0,0,0.152);

  table{
    margin: 0;

    th, td{
      padding: 0;
    }
  }
}




@media screen and ( max-width: $breakpoints-xl ){
  .main-nav{
    padding-top: 30px;
    margin-bottom: 30px;
  }

  .main-content .logo{
    margin-bottom: 60px;
  }
}

@media screen and ( max-width: $breakpoints-lg ){

  .main-content{
    padding-right: 55%;

    h1, .h1{
      font-size: 28px;
    }
  }

  .about{
    &-info{
      width: 45%;
    }
    &-content{
      padding-right: 55%;
      padding-top: 0;
    }
  }

  .company{
    flex-direction: column;
    text-align: center;
    margin-bottom: 25px;

    &-logo{
      flex-basis: auto;
      margin-bottom: 10px;
    }

    &-opt{
      margin: 15px 0 0 0;
      flex-basis: auto;
    }
  }
}

@media screen and ( max-width: $breakpoints-md ){
	.main{
    &-content{
      padding-right: 0;     

      .logo{
        margin-bottom: 32px;
      }

      h2, .h2{
        margin-bottom: 30px;
      }
    }

    &-section{
      padding-bottom: 300px;

      &:before, &:after{
        content: none;
      }
    }
  }

  .about{ 
    padding-bottom: 50px;

    &-info{
      margin: -300px 0 0 60px;
      float: none;
      width: auto;

      .block{
        margin-left: -60px;
      }
    }

    &-content{
      padding-right: 0;
      padding-top: 30px;
    }

    &-list{
    }
  } 

  .company {
    flex-direction: row;
    text-align: left;
    margin-bottom: 40px;
  }

  .main-nav{
    position: relative;
    display: block;
    padding: 0;

    .btn-wrap{
      float: right;
      margin: 0;
      position: relative;
      z-index: 10;
      top: 36px;
    }
  }

  .why{
    &-columns{
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;

      .column{
        width: 50%;
        flex-basis: 50%;
        margin-bottom: 50px;
      }

      .diagram-column{
        margin-bottom: 0;

        &:before{
          content: none;
        }
      } 
    }
  }

  .code-block{
    &-left, &-right{
      float: none;
      width: auto;
      margin: 0 0 40px;
    }

    &-left{
      padding-right: 80px;
    }

    &-right{
      .video{
        margin: 0 auto 30px;
      }
    }
  }

  .cond{
    &-main{
      float: none;
      width: 100%;
    }

    &-content{
      margin-top: -30px;

    }
  }

  .pref-list{    
    border: 2px dashed rgba(0,0,0,0.4);
    border-radius: 0 0 40px 20px;
    padding: 50px 34px 30px 34px;
  }

  .how{
    &-content{
      .how-block{
        width: 100%;
      }
    }
  }  

  #stat{
    .modal-part{
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
}

@media screen and ( max-width: $breakpoints-sm ){

  .pref{
    &-list{
      flex-wrap: wrap;
      padding-bottom: 1px;
    }

    &-item{
      width: 50%;
      flex-basis: 50%;
      text-align: center;
      margin-bottom: 40px;
      padding: 0 10px;
    }
  }

  .advantages{
    padding-left: 0;
    text-align: center;

    .item, .item:last-child{
      margin: 15px 10px 0;
      padding: 0;

      &:after{
        content: none;
      }
    }
  }

  .modal-columns .column{
    width: 100%;
  }

  .modal-double-content{
    flex-direction: column;
  }

  .modal-part{
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and ( max-width: $breakpoints-xs ){

  .main-nav{
    .btn-wrap{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 500;
      background: rgba(35,46,64,0.95);
      text-align: center;
      top: inherit;
      padding: 7px;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);

      &:before{
        content: none;
      }

      .btn{
        display: inline-block;
        padding-top: 11px;
        padding-bottom: 11px;
        background: linear-gradient(to bottom, #FED683 0%, #EAAF33 100%);
      } 
    }
  }

  .main-content{
    h1, .h1{
    }
    h2, .h2{
      font-size: 30px;
    }
  }

  .about{
    &-info{
      margin-left: 0;
      margin-top: -325px;

      .block{
        margin-left: 0;
      }
    }
  }

  .block{
    padding: 45px 15px 30px;

    &-title{
      text-align: center;
    }
  }

  .main-section{
    padding-bottom: 350px;
  }

  .daily{
    padding-top: 102px;
    padding-left: 24px;
    padding-right: 24px;

    &-num{
      left: 50%;
      top: -40px;
      margin-left: -61px;
    }
  }

  .why-columns{

    .column{
      width: 100%;
      flex-basis: 100%;
      text-align: center;
    }

    .diagram-column{
      text-align: left;
    }
  }

  .legend{
    max-width: 320px;
    margin: 0 auto;

    td{
      padding-left: 20px;
    }
  }

  .code-block{
    &-left{
      padding-right: 0;
    }
  }

  .input{
    font-size: 16px;
  }

  .footer{
    padding-bottom: 90px;
  }
}

@media screen and ( max-width: $breakpoints-xs ){
  .calc{

    &-top{
      padding-right: 0;
    }

    &-time{
      position: relative;
      margin:  14px 0 0;
      display: inline-block;
      top: 0;
      width: 100%;
      padding-left: 40px;
      text-align: left;
      white-space: nowrap;

      .svgicon{
        position: absolute;
        top: 50%;
        left: 0;
        margin: -14px 0 0;
      }
    }

    &-top{
      margin-bottom: 30px;
    }
  }

  .table-row{
    display: block;
    margin-bottom: 13px;

    .label{
      margin-bottom: 5px;
    }

    .badge{
      display: block;
    }
  }

  .company{
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    &-logo{
      margin-right: 0;
    }
  }

  .modal{
    padding: 35px 24px;

    &-title{
      margin-bottom: 24px;
    }
  }

  .modal-double{
    padding: 0;
  }

  .fancybox-slide--html .fancybox-close-small{
    right: 14px;
  }

  .fancybox-slide--html .modal-double .fancybox-close-small{
    top: 14px;
    right: 14px;
  }

  .modal-part .block{
    padding-left: 16px;
    padding-right: 16px;
  }

  .witem{
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .wd-item{
    flex-direction: column;
    text-align: center;

    &-btns{
      margin: 20px 0 0 ;
    }
  }

  .stat-columns .col{
    width: 100%;
    flex: 0 0 100%;
  }

  .info-icon .comment{
    width: 220px;
  }

  .ref-levels{
    margin-left: -16px;
    margin-right: -16px;    
  }

  .ref-level{
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    flex-wrap: wrap;

    .info-icon{
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .btn{
      margin: 7px 0 0;
    }

    &:first-child{
      .info-icon{
        top: 0;
      }
    }

    &-opts{
      width: 100%;
      text-align: center;
    }
  }

  .ref-item-top, .ref-item-bottom{
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    &>div{
      text-align: left;
      margin-left: 0;
    }
  }
}

@media screen and ( max-width: 400px ){

  .stats{
    &-row{
      margin-left: 0;
      margin-right: 0;
      background: none;
      padding: 0;
    }

    &-item{
      padding: 10px;
      width: 100%;

      &:last-child:before{
        content: none;
      }
    }
  }

  .code-block{
    &-title{
      padding-left: 0;

      .copy{
        right: 0;
      }
    }
  }

  pre[class*="language-"].line-numbers{
    padding-left: 38px;
  }

  .language-javascript{
    font-size: 12px!important;
  }

  .pref-item{
    width: 100%;
    flex-basis: 100%;
  }

  .how-content .how-block{
    .block{
      padding-left: 30px;
      padding-right: 30px;
    }

    .table{
      .label{
        margin-bottom: 10px;
        padding: 0;
      }
    }
  }

  .how-title{
    margin-bottom: 35px;
  }

  .how{
    .wallet{
      margin-top: 0;
      padding-top: 24px;
    }
  }

  .how-buttons{
    display: block;

    &>div{
      width: 100%;
      margin-top: 10px;
    }
  }

  .wallet .adress{
    font-size: 13px;
  }
}