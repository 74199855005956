html{
  height: 100%;
  background: $bg; 
  font-size: 16px;
  font-family: $font;
} 

body{
  background: $bg; 
  height: 100%;
  min-width: 320px;
  font-family: $font;
  line-height: 1.55em;
  font-weight: normal;
  color: $text-color;
  font-size: 16px;

  &.menu-opened{
    overflow: hidden;
  }
}

*, *:before, *:after{
  box-sizing: border-box;
}

.page-wrap{
  position: relative;
  overflow: hidden;
  background: inherit;
}

img, svg{ 
  max-width: 100%;
  height: auto;
}

::selection{
  color: #FFF;
  background: $color2;
} 

*, *:before, *:after{
  box-sizing: border-box;
}

p{
  margin-top: 0;
  margin-bottom: 20px;

  &:last-child{
    margin-bottom: 0
  }
}

small{
  font-size: $font-size-small;
}

hr{
  background-color: $gray;
  height: 1px;
  border: none;
  margin: rems(42px) 0 rems(28px);
  border-color: $borders;
  color: $borders;
  background: $borders;
}

strong{
  color: $headings;
}


img[class*="wp-image-"]{
  display: block;
  margin: 0 auto;
  border-radius: rems(18px);
}

a{
  color: $color;
  text-decoration: none;
  transition: color 0.24s, background-color 0.24s, border-color 0.24s, transform 0.24s;
  border-bottom: 1px solid;

  &:hover, &:active{
    text-decoration: none;
    color: inherit;
    border-bottom-color: transparent;
  }
}


/****** LISTS ******/

ul, menu, dir{
  padding: 0 0 0 rems(29px);
  margin: 0 0 rems(36px);
  list-style-type: none;
}

li{
  margin-bottom: rems(16px);
  position: relative;

  &:before{
    content: "";
    position: absolute;
    top: rems(11px);
    left: rems(-29px);
    width: rems(5px);
    height: rems(5px);
    border-radius: 50%;
    border-radius: rems(5px);
  }
}

ol {
  padding: 0;
  margin: 0 0 rems(36px);
  list-style-type: none;
  counter-reset: myCounter;

  li{
    position: relative;
    padding-left: rems(40px);
  }
  
  li:before {
    counter-increment: myCounter;
    content: counter(myCounter, decimal-leading-zero)  ".";
    position: absolute;
    top: 0;
    left: 0;
    color: $color;
    font-weight: bold;
    background: none;
    width: auto;
    height: auto;
  }
}

.list-unstyled{
  &>li{
    padding-top: 0;

    &:before, &:after{
      content: none;
    }
  }
}

/****** TABLES ******/

table{ 
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0 0 1rem;
  line-height: normal;

  tr{
  }
  td, th{
    vertical-align: middle;
    padding-bottom: 23px;
    padding-right: 20px;
  }
}


 

@media screen and ( max-width: $breakpoints-lg ){
   html{
  }
}

@media screen and ( max-width: $breakpoints-md ){
}

@media screen and ( max-width: $breakpoints-sm ){

}

@media screen and ( max-width: $breakpoints-xs ){

  h2, .h2 {
    font-size: 24px;
  }

}